/* eslint-disable max-len */
import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { CloseOutlined } from '@ant-design/icons';
import { Drawer } from 'antd';
import QRCode from 'qrcode.react';
import Cookies from 'js-cookie';
import USER_TOKEN_KEY from '../../constants';
import i18n from '../../I18n';

import styles from './CheckInScreen.module.css';

@observer
export default class CheckInScreen extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
  }

  render() {
    return (
      <Drawer
        visible={this.props.isShown}
        width="min(550px, 100%)"
        closable={false}
        bodyStyle={{
          backgroundColor: '#F7F7F7',
          display: 'flex',
          flexDirection: 'column',
          padding: 16,
          boxShadow: '0px 20px 34px rgba(0, 0, 0, 0.08)'
        }}
      >
        <CloseOutlined className={styles.closeIcon} onClick={this.props.onClose} />
        <div className={styles.main}>
          <h3 className={styles.subText}>{this.props.subtitle}</h3>
          <h1 className={styles.title}>{this.props.title}</h1>
          <div className={styles.name}>{this.props.name}</div>
          <div className={styles.nickname}>{this.props.nickname}</div>
          <div className={styles.qrcodeContainer}>
            <QRCode
              value={`${window.location && window.location.origin}/api/v1/session/${this.props.sessionId}/checkIn/${Cookies.get(USER_TOKEN_KEY)}?t=${Date.now()}`}
              size={210}
            />
          </div>
          <h3 className={styles.subText}>{i18n.t('checkin_qrcode_message')}</h3>
        </div>
      </Drawer>
    );
  }
}

CheckInScreen.propTypes = {
  isShown: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  sessionId: PropTypes.string,
  name: PropTypes.string,
  nickname: PropTypes.string
};

CheckInScreen.defaultProps = {
  title: '',
  subtitle: null,
  sessionId: null,
  name: null,
  nickname: null
};
