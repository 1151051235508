import Cookies from 'js-cookie';

import API from '../apis';
import USER_TOKEN_KEY from '../constants';

export default class Lottery {
  static async listSession(limit, anchor) {
    const token = Cookies.get(USER_TOKEN_KEY);
    const res = await API.lottery.listSession(
      token,
      limit,
      anchor
    );
    return {
      data: res.data?.data,
      anchor: res.data?.anchor
    };
  }

  static async listLottery(id, limit, anchor, includeResult) {
    const token = Cookies.get(USER_TOKEN_KEY);
    const res = await API.lottery.listLottery(
      token,
      id,
      limit,
      anchor,
      includeResult
    );
    return {
      data: res.data?.data,
      anchor: res.data?.anchor
    };
  }

  static async listResult(sid, id, limit, anchor) {
    const token = Cookies.get(USER_TOKEN_KEY);
    const res = await API.lottery.listResult(
      token,
      sid,
      id,
      limit,
      anchor
    );
    return {
      data: res.data?.data,
      anchor: res.data?.anchor
    };
  }
}
