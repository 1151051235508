import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import axios from 'axios';

import en from './locales/en-US.gen';
import zh from './locales/zh-TW.gen';

const languageDetector = new LanguageDetector(null, { order: ['navigator'] });

i18n
  .use(languageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    fallbackLng: 'en-US',
    interpolation: {
      escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
    resources: {
      'en-US': {
        translation: en
      },
      'zh-TW': {
        translation: zh
      }
    }
  });


const loadI18n = async () => {
  try {
    const result = await axios({
      method: 'get',
      url: `https://storage.googleapis.com/mmparty/${window.location.hostname.split('.')[0]}/i18n/en.json?t=${Date.now()}`,
      headers: {
        'Content-Type': 'application/json'
      }
    });

    i18n.addResourceBundle('en-US', 'translation', result.data);
    console.log('load en-US');
  } catch (e) {
    // no i18n file
  }

  try {
    const result = await axios({
      method: 'get',
      url: `https://storage.googleapis.com/mmparty/${window.location.hostname.split('.')[0]}/i18n/zh.json?t=${Date.now()}`,
      headers: {
        'Content-Type': 'application/json'
      }
    });

    i18n.addResourceBundle('zh-TW', 'translation', result.data);
    console.log('load zh-TW');
  } catch (e) {
    // no i18n file
  }
};

i18n.loadI18n = loadI18n;
export default i18n;

