/* This source code is exported from pxCode, you can get more document from https://www.pxcode.io */
import React from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import styles from './ResultCard.module.css';

export default function ResultCard(props) {
  return (
    <div className={`result-card ${cn(styles.resultCard, styles.resultCard_layout, props.myResult && styles.resultCardRed)}`}>
      <div className={cn(styles.block16, styles.block16_layout)}>
        <div className={cn(styles.subtitle, styles.subtitle_layout)}>{props.title}</div>
        <div className={cn(styles.title, styles.title_layout)}>{props.award}</div>
      </div>
    </div>
  );
}

ResultCard.propTypes = {
  title: PropTypes.string.isRequired,
  award: PropTypes.string.isRequired,
  myResult: PropTypes.bool.isRequired
};

ResultCard.inStorybook = true;
