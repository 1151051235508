/* eslint-disable max-len */
import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { CloseOutlined } from '@ant-design/icons';
import { Drawer } from 'antd';

import styles from './AnnouncementScreen.module.css';

@observer
export default class AnnouncementScreen extends React.Component {
  constructor(props) {
    super(props);
    this.props = props;
  }

  render() {
    return (
      <Drawer
        visible={this.props.isShown}
        width="min(550px, 100%)"
        closable={false}
        bodyStyle={{
          backgroundColor: '#F7F7F7',
          display: 'flex',
          flexDirection: 'column',
          padding: this.props.type === 'image' ? 0 : 16,
          boxShadow: '0px 20px 34px rgba(0, 0, 0, 0.08)'
        }}
      >
        <CloseOutlined className={styles.closeIcon} onClick={this.props.onClose} />
        <div className={styles.main}>
          {
            this.props.type === 'image' && (
              <img src={this.props.content} alt="Announcement" className={styles.image} />
            )
          }
          {
            this.props.type === 'text' && (
              <p className={styles.content}>{ this.props.content}</p>
            )
          }
        </div>
      </Drawer>
    );
  }
}

AnnouncementScreen.propTypes = {
  isShown: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  content: PropTypes.string
};

AnnouncementScreen.defaultProps = {
  content: null
};
